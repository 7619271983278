import { SlideMenu as SlideMenus } from 'primereact/slidemenu';  

const SlideMenu = ({...rest}) => {

    return (
        <SlideMenus {...rest} />
    )

};

export default SlideMenu;