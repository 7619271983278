import { TabMenu as TabMenus } from 'primereact/tabmenu';  

const TabMenu = ({...rest}) => {

    return (
        <TabMenus {...rest} />
    )

};

export default TabMenu;