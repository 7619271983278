import { Column as Columns } from 'primereact/column';  

const Column = ({...rest}) => {

    return (
        <Columns {...rest} />
    )

};

export default Column;