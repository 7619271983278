import { SplitButton as SplitButtons } from 'primereact/splitbutton';  

const SplitButton = ({...rest}) => {

    return (
        <SplitButtons {...rest} />
    )

};

export default SplitButton;