import { Checkbox as Checkboxs } from 'primereact/checkbox';  

const Checkbox = ({...rest}) => {

    return (
        <Checkboxs {...rest} />
    )

};

export default Checkbox;