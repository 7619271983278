import { Badge as Badges } from 'primereact/badge';  

const Badge = ({...rest}) => {

    return (
        <Badges {...rest} />
    )

};

export default Badge;