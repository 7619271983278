import { TieredMenu as TieredMenus } from 'primereact/tieredmenu';  

const TieredMenu = ({...rest}) => {

    return (
        <TieredMenus {...rest} />
    )

};

export default TieredMenu;