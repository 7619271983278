import { Ripple as Ripples } from 'primereact/ripple';  

const Ripple = ({...rest}) => {

    return (
        <Ripples {...rest} />
    )

};

export default Ripple;