import { Menu as Menus } from 'primereact/menu';  

const Menu = ({...rest}) => {

    return (
        <Menus {...rest} />
    )

};

export default Menu;