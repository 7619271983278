import { BreadCrumb as BreadCrumbs } from 'primereact/breadcrumb';  

const BreadCrumb = ({...rest}) => {

    return (
        <BreadCrumbs {...rest} />
    )

};

export default BreadCrumb;