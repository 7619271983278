import { Carousel as Carousels } from 'primereact/carousel';  

const Carousel = ({...rest}) => {

    return (
        <Carousels {...rest} />
    )

};

export default Carousel;