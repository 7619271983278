import { Galleria as Gallerias } from 'primereact/galleria';  

const Galleria = ({...rest}) => {

    return (
        <Gallerias {...rest} />
    )

};

export default Galleria;