import { Menubar as Menubars } from 'primereact/menubar';  

const Menubar = ({...rest}) => {

    return (
        <Menubars {...rest} />
    )

};

export default Menubar;