import { Tag as Tags } from 'primereact/tag';  

const Tag = ({...rest}) => {

    return (
        <Tags {...rest} />
    )

};

export default Tag;