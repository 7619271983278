import { CascadeSelect as CascadeSelects } from 'primereact/cascadeselect';

const CascadeSelect = ({...rest}) => {

    return (
        <CascadeSelects {...rest} />
    )

};

export default CascadeSelect;