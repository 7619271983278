import { InputNumber as InputNumbers } from 'primereact/inputnumber';  

const InputNumber = ({...rest}) => {

    return (
        <InputNumbers {...rest} />
    )

};

export default InputNumber;