import { ListBox as ListBoxs } from 'primereact/listbox';  

const ListBox = ({...rest}) => {

    return (
        <ListBoxs {...rest} />
    )

};

export default ListBox;