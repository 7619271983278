import { Password as Passwords } from 'primereact/password';  

const Password = ({...rest}) => {

    return (
        <Passwords {...rest} />
    )

};

export default Password;