import { Toolbar as Toolbars } from 'primereact/toolbar';  

const Toolbar = ({...rest}) => {

    return (
        <Toolbars {...rest} />
    )

};

export default Toolbar;