import { InputTextarea as InputTextareas } from 'primereact/inputtextarea';  

const InputTextarea = ({...rest}) => {

    return (
        <InputTextareas {...rest} />
    )

};

export default InputTextarea;