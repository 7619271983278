import { DataTable as DataTables } from 'primereact/datatable';  

const DataTable = ({...rest}) => {

    return (
        <DataTables {...rest} />
    )

};

export default DataTable;