import { MultiStateCheckbox as MultiStateCheckboxs } from 'primereact/multistatecheckbox';  

const MultiStateCheckbox = ({...rest}) => {

    return (
        <MultiStateCheckboxs {...rest} />
    )

};

export default MultiStateCheckbox;