import { ScrollPanel as ScrollPanels } from 'primereact/scrollpanel';  

const ScrollPanel = ({...rest}) => {

    return (
        <ScrollPanels {...rest} />
    )

};

export default ScrollPanel;