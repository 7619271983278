import { Tree as Trees } from 'primereact/tree';  

const Tree = ({...rest}) => {

    return (
        <Trees {...rest} />
    )

};

export default Tree;