import { Sidebar as Sidebars } from 'primereact/sidebar';  

const Sidebar = ({...rest}) => {

    return (
        <Sidebars {...rest} />
    )

};

export default Sidebar;