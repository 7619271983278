import { Knob as Knobs } from 'primereact/knob';  

const Knob = ({...rest}) => {

    return (
        <Knobs {...rest} />
    )

};

export default Knob;