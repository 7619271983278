import { Mention as Mentions } from 'primereact/mention';  

const Mention = ({...rest}) => {

    return (
        <Mentions {...rest} />
    )

};

export default Mention;