import { Chips as Chipss } from 'primereact/chips';  

const Chips = ({...rest}) => {

    return (
        <Chipss {...rest} />
    )

};

export default Chips;