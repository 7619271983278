import { Chip as Chips } from 'primereact/chip';  

const Chip = ({...rest}) => {

    return (
        <Chips {...rest} />
    )

};

export default Chip;