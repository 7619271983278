import { InputSwitch as InputSwitchs } from 'primereact/inputswitch';  

const InputSwitch = ({...rest}) => {

    return (
        <InputSwitchs {...rest} />
    )

};

export default InputSwitch;