import { PanelMenu as PanelMenus } from 'primereact/panelmenu';  

const PanelMenu = ({...rest}) => {

    return (
        <PanelMenus {...rest} />
    )

};

export default PanelMenu;