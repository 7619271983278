import { ScrollTop as ScrollTops } from 'primereact/scrolltop';  

const ScrollTop = ({...rest}) => {

    return (
        <ScrollTops {...rest} />
    )

};

export default ScrollTop;