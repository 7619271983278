import { TriStateCheckbox as TriStateCheckboxs } from 'primereact/tristatecheckbox';  

const TriStateCheckbox = ({...rest}) => {

    return (
        <TriStateCheckboxs {...rest} />
    )

};

export default TriStateCheckbox;