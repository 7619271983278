import { Calendar as Calendars } from 'primereact/calendar';  

const Calendar = ({...rest}) => {

    return (
        <Calendars {...rest} />
    )

};

export default Calendar;