import { ProgressSpinner as ProgressSpinners } from 'primereact/progressspinner';  

const ProgressSpinner = ({...rest}) => {

    return (
        <ProgressSpinners {...rest} />
    )

};

export default ProgressSpinner;