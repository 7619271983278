// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgb(179, 177, 177);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(150, 150, 150);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(135, 135, 135);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:active {
  background: rgb(120, 120, 120);
  border-radius: 10px;
}

p {
  margin-bottom: 8px;
}

table .p-column-header-content {
  justify-content: center;
}

.rowTable .p-datatable .p-datatable-tbody > tr > td {
  padding: 0 !important;
}

.rowTable a {
  color: #343a40;
}

.p-dropdown-panel {
  max-width: 300px; /* Đặt kích thước tối đa cho tùy chọn */
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  overflow: auto;
  white-space: normal;
  line-height: 1.6rem
};


`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":";AACA;EACE,UAAU;AACZ;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB,EAAE,uCAAuC;AAC3D;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB;AACF,CAAA","sourcesContent":["\n::-webkit-scrollbar {\n  width: 8px;\n}\n\n::-webkit-scrollbar-track {\n  background: rgb(179, 177, 177);\n  border-radius: 10px;\n}\n\n::-webkit-scrollbar-thumb {\n  background: rgb(150, 150, 150);\n  border-radius: 10px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n  background: rgb(135, 135, 135);\n  border-radius: 10px;\n}\n\n::-webkit-scrollbar-thumb:active {\n  background: rgb(120, 120, 120);\n  border-radius: 10px;\n}\n\np {\n  margin-bottom: 8px;\n}\n\ntable .p-column-header-content {\n  justify-content: center;\n}\n\n.rowTable .p-datatable .p-datatable-tbody > tr > td {\n  padding: 0 !important;\n}\n\n.rowTable a {\n  color: #343a40;\n}\n\n.p-dropdown-panel {\n  max-width: 300px; /* Đặt kích thước tối đa cho tùy chọn */\n}\n\n.p-dropdown-panel .p-dropdown-items .p-dropdown-item {\n  overflow: auto;\n  white-space: normal;\n  line-height: 1.6rem\n};\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
