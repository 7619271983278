import { Dialog as Dialogs } from 'primereact/dialog';  

const Dialog = ({...rest}) => {

    return (
        <Dialogs {...rest} />
    )

};

export default Dialog;