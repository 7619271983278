import { Slider as Sliders } from 'primereact/slider';  

const Slider = ({...rest}) => {

    return (
        <Sliders {...rest} />
    )

};

export default Slider;