import { OrderList as OrderLists } from 'primereact/orderlist';  

const OrderList = ({...rest}) => {

    return (
        <OrderLists {...rest} />
    )

};

export default OrderList;