import { Timeline as Timelines } from 'primereact/timeline';  

const Timeline = ({...rest}) => {

    return (
        <Timelines {...rest} />
    )

};

export default Timeline;