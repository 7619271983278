import { Messages as Messagess } from 'primereact/messages';  

const Messages = ({...rest}) => {

    return (
        <Messagess {...rest} />
    )

};

export default Messages;