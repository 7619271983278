import { StyleClass as StyleClasss } from 'primereact/styleclass';  

const StyleClass = ({...rest}) => {

    return (
        <StyleClasss {...rest} />
    )

};

export default StyleClass;