import { FileUpload as FileUploads } from 'primereact/fileupload';  

const FileUpload = ({...rest}) => {

    return (
        <FileUploads {...rest} />
    )

};

export default FileUpload;