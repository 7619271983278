import { Skeleton as Skeletons } from 'primereact/skeleton';  

const Skeleton = ({...rest}) => {

    return (
        <Skeletons {...rest} />
    )

};

export default Skeleton;