import { TreeTable as TreeTables } from 'primereact/treetable';  

const TreeTable = ({...rest}) => {

    return (
        <TreeTables {...rest} />
    )

};

export default TreeTable;