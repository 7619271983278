import { ColorPicker as ColorPickers } from 'primereact/colorpicker';  

const ColorPicker = ({...rest}) => {

    return (
        <ColorPickers {...rest} />
    )

};

export default ColorPicker;