import { Dock as Docks } from 'primereact/dock';  

const Dock = ({...rest}) => {

    return (
        <Docks {...rest} />
    )

};

export default Dock;