import { OverlayPanel as OverlayPanels } from 'primereact/overlaypanel';  

const OverlayPanel = ({...rest}) => {

    return (
        <OverlayPanels {...rest} />
    )

};

export default OverlayPanel;