import { ProgressBar as ProgressBars } from 'primereact/progressbar';  

const ProgressBar = ({...rest}) => {

    return (
        <ProgressBars {...rest} />
    )

};

export default ProgressBar;