import { RadioButton as RadioButtons } from 'primereact/radiobutton';  

const RadioButton = ({...rest}) => {

    return (
        <RadioButtons {...rest} />
    )

};

export default RadioButton;