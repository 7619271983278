import { ToggleButton as ToggleButtons } from 'primereact/togglebutton';  

const ToggleButton = ({...rest}) => {

    return (
        <ToggleButtons {...rest} />
    )

};

export default ToggleButton;