import { AvatarGroup as AvatarGroups } from 'primereact/avatargroup';  

const AvatarGroup = ({...rest}) => {

    return (
        <AvatarGroups {...rest} />
    )

};

export default AvatarGroup;