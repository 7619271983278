import { Tooltip as Tooltips } from 'primereact/tooltip';  

const Tooltip = ({...rest}) => {

    return (
        <Tooltips {...rest} />
    )

};

export default Tooltip;