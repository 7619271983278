import { Chart as Charts } from 'primereact/chart';  

const Chart = ({...rest}) => {

    return (
        <Charts {...rest} />
    )

};

export default Chart;