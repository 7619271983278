import { MultiSelect as MultiSelects } from 'primereact/multiselect';  

const MultiSelect = ({...rest}) => {

    return (
        <MultiSelects {...rest} />
    )

};

export default MultiSelect;