import { ContextMenu as ContextMenus } from 'primereact/contextmenu';  

const ContextMenu = ({...rest}) => {

    return (
        <ContextMenus {...rest} />
    )

};

export default ContextMenu;