import { OrganizationChart as OrganizationCharts } from 'primereact/organizationchart';  

const OrganizationChart = ({...rest}) => {

    return (
        <OrganizationCharts {...rest} />
    )

};

export default OrganizationChart;