import { SelectButton as SelectButtons } from 'primereact/selectbutton';  

const SelectButton = ({...rest}) => {

    return (
        <SelectButtons {...rest} />
    )

};

export default SelectButton;