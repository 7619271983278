import { Panel as Panels } from 'primereact/panel';  

const Panel = ({...rest}) => {

    return (
        <Panels {...rest} />
    )

};

export default Panel;